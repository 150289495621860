@import 'assets/styles/variables';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 18px;
}

.filename {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 1rem;
  .label {
    color: $color_gray;
    margin-right: 1rem;
  }
}

.button {
  white-space: nowrap;
}
