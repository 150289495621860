@import "assets/styles/variables";
@import "assets/styles/functions";

.cpmValue {
  text-align: center;
  color: $color_secondary;
  font-size: calculateRem(14px);

}

.cpmLabel {
  color: $color_gray;
  font-weight: 500;
  font-size: calculateRem(12px);
  text-align: center;
}

.padding {
  padding: calculateRem(9px);
}

.detailText {
  color: $color_gray;
  padding: 0 calculateRem(9px);
  font-weight: 500;
  font-size: calculateRem(12px);
  line-height: calculateRem(15px);
}

.values {
  color: $color_secondary;
  padding: 0 calculateRem(9px);
  font-weight: 800;
  font-size: calculateRem(24px);
  line-height: calculateRem(29px);
  text-transform: uppercase;
}

.detailValues {
  color: $color_primary;
  padding: 0 calculateRem(9px);
  font-weight: 500;
  font-size: calculateRem(12px);
  line-height: calculateRem(15px);
}

.title {
  padding: calculateRem(9px);
  margin-bottom: 1rem !important;
}

.declineReason {
  color: $color_error;
}

.declineReasonText {
  color: $color_text;
  font-weight: 500;
  padding: 0 calculateRem(9px);
}