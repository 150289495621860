@import "assets/styles/variables";

.wrapper {
  margin-left: 1rem;
}

.formControl {
  margin-bottom: 0 !important;
  width: 100%;
  >p {
    margin-top: -5px;
  }
}

.input {
  font-weight: 800 !important;
  font-size: 30px !important;
  line-height: 37px;
  min-width: 120px;
  color: $color_text;
  border: none;

  input {
    padding: 0 !important;

    &::placeholder {
      color: $color_gray_middle;
      opacity: 1;
    }
  }
}

.title,
.placeholder {
  text-transform: none !important;
}

.placeholder {
  color: $color_gray_middle;
}

.editIcon {
  margin-left: 1rem;
  cursor: pointer;
}

.titleContainer {
  position: relative;
  width: max-content;
  >p {
    margin-top: -5px;
  }
}
