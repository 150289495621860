@import 'assets/styles/variables';

.zoom {
  margin-bottom: 10px;
  box-shadow: 0 1px 3px 0 rgba(37,37,37,0.3);
  height: max-content;
  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 30px;
    height: 30px;
    background: #fff;
    border: none;
    font-size: 25px;
    color: $color_primary;
    outline: none;
    cursor: pointer;

    &:not(:last-child):after {
      content: '';
      display: flex;
      position: absolute;
      height: 1px;
      background-color: #e6e6e6;
      width: 24px;
      bottom: 0;
    }

    &:disabled {
      cursor: default;
      background-color: $color_gray;
      color: #fff;
      &:after {
        display: none;
      }
    }

  }
}
