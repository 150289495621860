@import "assets/styles/variables";

.wrapper {
  background: $color_text;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 10;
  height: $footer_height;
  border-top: 1px solid #e4e8eb;
  line-height: 5rem;
}

.copy,
.version {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #f9f9f9;
  opacity: 0.5;
}

.link {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff !important;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.version {
  margin-right: 20px;
}
