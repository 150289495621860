@import 'functions';
@import 'variables';

.table-stepper {
  min-height: 4rem;
  padding: 0 !important;
  margin: 2rem;
  border-radius: 1rem !important;
  border: 1px solid $color_gray_middle;
  display: flex;
  justify-content: center;

  button {
    font-size: 1rem;
    color: $color_text;
  }

  ul {
    width: 100%;

    li:first-child button,
    li:last-child button {
      width: 100%;
      padding: 0 .5rem;
      border-radius: .5rem;

      svg { display: none; }
    }

    li:first-child {
      margin-right: auto;
      margin-left: 2rem;
      button {
        &:after {
          content: "Prev";
          background: url('../images/icons/arrowLeft.svg') no-repeat left;
          color: $color_text;
          padding: 0 0 0 3rem;
        }
        &:disabled {
          &:after {
            content: '';
            background: transparent;
          }
        }
      }
    }

    li:last-child {
      margin-left: auto;
      margin-right: 2rem;
      button {
        &:before {
          content: "Next";
          background: url('../images/icons/arrowRight.svg') no-repeat right;
          color: $color_text;
          padding: 0 3rem 0 0;
        }
        &:disabled {
          &:before {
            content: '';
            background: transparent;
          }
        }
      }
    }
  }
}

.subtitle-text {
  font-weight: 500;
  font-size: calculateRem(14px);
  line-height: calculateRem(19px);
  color: $color_gray;
}

.gray-container {
  width: 100%;
  background: $color_gray_lighter;
  border-radius: 1rem;
  padding: 2.5rem;
  margin-bottom: 1.875rem;
}

.tab-stepper {
  min-height: 4rem;
  padding: 0 !important;
  border-radius: 1rem !important;
  border: 1px solid $color_gray_middle;
  display: flex;

  button {
    font-size: 1rem;
    color: $color_text;
    border-radius: calculateRem(11px);
    margin-top: .55rem;
    height: calculateRem(44px);
  }
}

.selected {
  background: $color_primary;
}

.selectedDisabled {
  background: $color_gray;
}

.disabled {
  color: $color_gray !important;
}

.disabledIcon {
  path { fill: $color_gray !important; }
}

.d-none {
  display: none !important;
}

.relative {
  position: relative !important;
}

/*-------------------- Scrollbar --------------------*/
/* width */
::-webkit-scrollbar {
  background-color:#fff;
  border-radius: 1rem;
  width: 1rem;
}

/* Track */
::-webkit-scrollbar-track {
  background-color:#fff;
  border-radius: 1rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color:#babac0;
  border-radius: 1rem;
  border: 4px solid #fff
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #acacb2;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display:none
}


/*-------------------- Buttons --------------------*/
.buttonLink {
  color: inherit !important;
  text-decoration: unset;
}

.movingBySvg {
  cursor: move;
  & > * {
    cursor: move;
    pointer-events: none;
  }
}