@import "assets/styles/variables";

.wrapper {
  margin-bottom: 5px !important;
}
.title {
  font-weight: 500;
  font-size: calculateRem(14px);
  line-height: calculateRem(21px);
  color: $color_primary;
}
