@import 'assets/styles/variables';
@import 'assets/styles/functions';

.deposit {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.depositStartAdornment {
  p {
    font-weight: 600 !important;
    font-size: calculateRem(20px) !important;
    line-height: calculateRem(24px) !important;
    text-transform: uppercase !important;
    color: $color_gray !important;
  }
}

.depositButtonWrapper {
  width: unset !important;
  .depositButton {
    color: #fff !important;
    background-color: $color_primary !important;
    font-weight: 600 !important;
    height: 100% !important;
    font-size: calculateRem(18px) !important;
    border-radius: inherit !important;
    &.disabled {
      background-color: $color_gray !important;
    }
  }
}

