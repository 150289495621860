@import "assets/styles/variables";

.border {
  position: relative;
  border: 1px solid $color_gray;
  border-radius: 20px;
  margin: 16px !important;
}

.legend {
  position: absolute;
  top: -12px;
  left: 32px;
  color: $color_gray_dark;
  background-color: $color_gray_lighter;
}