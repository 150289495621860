@import 'assets/styles/variables.scss';

.legend {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .bubble {
    width: 15px;
    height: 15px;
    min-width: 15px;
    min-height: 15px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .legendItem {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  .title {
    color: $color_gray_dark;
  }
}
