@import "assets/styles/variables";

.wrapper {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.noWrap {
  white-space: nowrap;
}

.variantLight {
  color: #888888;
}

.variantRegular {
  color: $color_text;
}

.fontWeightNormal {
  font-weight: 500;
}

.fontWeightBold {
  font-weight: 600;
}
