.creativeWrapper {
  overflow: auto;
}

.previewWrapper {
  min-width: 25% !important;
}

.preview {
  height: 115px;
  border: none;
}

.titleText {
  padding: .2rem 0 0 0;
}