@import 'assets/styles/variables';

.value {
  display: flex;
  align-items: center;
}

.status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;

  &.active {
    background-color: $color_secondary;
  }

  &.inactive {
    background-color: $color_error;
  }
}

.select {
  background-color: inherit!important;
}