.searchWrapper {
  padding: 2px 4px;
  display: flex;
  align-items: center;
  margin: .45rem .45rem .45rem auto;
  border-bottom: 1px solid rgba(191, 191, 191, 0.87);
  border-radius: unset !important;
  min-width: 15rem;
}

.searchIconButton {
  padding: 5px !important;
}

.searchInput {
  flex: 1;
}