@import 'assets/styles/variables';

.tableWrapper {
  position: relative;

  :global .MuiPaper-root {
    box-shadow: none;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    overflow: hidden;
    background-color: $color_gray_lighter;

    .MuiTableCell-root {
      padding: .875rem;
      border-bottom: none;
      &:first-child {
        padding-left: 1.75rem;
      }
      &:nth-child(2) {
        width: auto;
        text-align: right;
        align-self: center;
      }
      &:last-child {
        padding-right: 1.75rem;
        text-align: center;
        width: auto;
        cursor: pointer;
      }
    }

    .MuiTableCell-head{
      padding: 1.1rem .875rem;
    }

    .MuiTableSortLabel-icon {
      margin: 0 .75rem;
      opacity: 0.75;

      &:hover {
        opacity: 1;
      }
    }

    .MuiTableSortLabel-active {
      .MuiTableSortLabel-iconDirectionAsc,
      .MuiTableSortLabel-iconDirectionDesc {
        path {
          &:first-child {
            fill: #bbbbbb;
          }
        }
      }
    }
  }

  :global .MuiTableHead-root {
    background-color: $color_primary;
    display: block;

    .MuiTableRow-head {
      display: flex;
    }

    .MuiTableCell-head {
      background-color: transparent;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.685rem;
      color: #fff;

      h2 > div {
        line-height: 1.685rem;
      }
    }

    .MuiTableCell-root {
      border-bottom: none;
      width: 100%;
    }
  }

  :global .MuiTableBody-root {
    background-color: $color_gray_lighter;
    display: block;
    height: 27rem;
    overflow: auto;

    .MuiTableRow-root {
      display: flex;
      position: relative;
      transform: scale(1);
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 1.875rem;
        right: 1.875rem;
        border-bottom: 1px solid #ebebeb;
      }
      &:last-child {
        &::after {
          border-bottom: none;
        }
      }
    }

    .MuiTableCell-body {
      font-weight: 500;
      font-size: 1rem;
      line-height: 2.6rem;
      color: $color_gray !important;

      &:first-child {
        padding-left: 1.75rem;
        width: 100%;
      }
      &:nth-child(2) {
        width: auto;
        align-self: center;
      }
      &:last-child {
        width: 4.75rem;
        cursor: default;
      }
    }
  }

  :global .PaginationItem-root-307.PaginationItem-disabled-318 {
    &:before,
    &:after {
      color: rgba(0, 0, 0, 0.26);
    }
  }
}

.filterComponenrWrapper {
  width: 100% !important;
}
