@import 'assets/styles/variables';

.budgetSpend,
.budgetTarget {
  font-weight: 500;
  font-size: .75rem;
  line-height: .9375rem;
  color: $color_text;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%
}

.budgetSpend {
  text-align: left;
}

.budgetTarget {
  text-align: right;
}

.warningWrapper {
  display: flex;
  align-items: center;

  .warningIcon {
    color: $color_error;
    margin-right: 5px;
  }
}
