@import 'assets/styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: auto;
}

.bar {
  span {
    text-transform: none !important;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.375rem;
  }

  .active {
    color: rgba(106, 35, 124, 1) !important;
  }

  .valid {
    color: rgba(106, 35, 124, .7) !important;
    border-bottom: 4px solid $color_accent !important;
    opacity: 1 !important;
  }

  .hidden {
    display: none;
  }

}