html, body {
  background-color: #fff !important;
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
  padding-bottom: 5rem;
  box-sizing: content-box;
}

.link { text-decoration: none; }
