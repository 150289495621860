@import "assets/styles/variables";

.primaryText {
  font-weight: 600;
  font-size: calculateRem(16px);
  line-height: calculateRem(21px);
  color: $color_text;
}

.secondaryText {
  font-weight: 500;
  font-size: calculateRem(14px);
  line-height: calculateRem(21px);
  color: $color_gray_dark;
}
