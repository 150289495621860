@import 'assets/styles/variables';
@import "assets/styles/functions";

.wrapper {
  width: 100%;
}

.menuItemWrapper {
  padding: .15rem 1.5rem !important;
}

.menuItemCheckbox {
  padding: 0 !important;
}

.menuItemText,
.menuItemTextReset {
  padding-left: .5rem;
}

.menuItemTextReset {
  color: $color_gray;
}

.labelTitle {
  color: $color_primary;
}

.error {
  color: $color_error;
}

.labelValue {
  color: $color_text;
}

.disabled {
  color: $color_gray !important;
}
