@import 'assets/styles/variables';

.optionsIconButton {
  color: $color_gray !important;
}

.label {
  color: $color_text;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
}

.icon {
  padding-right: .5rem;
  svg {
    vertical-align: middle;
  }
}
