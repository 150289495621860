@import 'assets/styles/functions';

.button {
  padding: calculateRem(15px) calculateRem(16px) !important;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus,
  &:hover {
    border-radius: 0 11px 11px 0;
  }
}
