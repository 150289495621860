@import 'assets/styles/variables';
@import 'assets/styles/functions';

.link {
  font-size: calculateRem(16px);
  font-weight: 600;
  color: $link_color_text;
  text-decoration: none;

  &:hover {
    color: $color_secondary;
  }
}
