@import "assets/styles/variables";

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.wrapper {
  animation: appear 0.3s ease-in;
  animation-fill-mode: forwards;
  position: absolute;
  background: linear-gradient(180deg, #ffffff 86.13%, #fcfcfc 100%), #ffffff;
  border-radius: 16px;
  padding: 30px;
  transition: all ease-in-out 0.5s;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.12);
  width: 300px;
  z-index: 20;
}

.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.title,
.abbr {
  font-weight: 800;
  font-size: 22px;
  line-height: 27px;
  text-transform: uppercase;
}

.title {
  color: $color_text;
  padding-right: 20px;
}
.abbr {
  color: $color_gray_middle;
}

.body {
  display: flex;
  flex-wrap: wrap;
}

.item {
  flex: 1 1 50%;
  justify-content: space-between;
  padding-right: 20px;
  margin-bottom: 22px;
  &:nth-child(3),
  &:nth-child(4) {
    margin-bottom: 0;
  }
}

.itemValue {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: $color_accent;
}

.itemDescription {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: $color_gray;
}
