@import 'assets/styles/functions';
@import 'assets/styles/variables';

.card {
  cursor: pointer;
  border-top: 2px solid $color_gray_light;
  padding: calculateRem(16px) calculateRem(30px);
  &:first-child {
    border-color: transparent;
  }
}
.wrapper {
  position: relative;
}

.title {
  font-weight: 600;
  font-size: calculateRem(20px);
  line-height: calculateRem(24px);
  text-transform: uppercase;
  color: $color_text;
}

.value {
  font-weight: 800;
  font-size: calculateRem(48px);
  line-height: calculateRem(59px);
  color: $color_gray;
}

.active {
  position: relative;
  border-color: transparent;
  & + * {
    border-color: transparent;
  }

  &::before {
    content: '';
    background: $color_gray_light;
    border-radius: calculateRem(15px);
    position: absolute;
    opacity: 0.5;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .value {
    color: $color_primary;
  }
}
