@import 'assets/styles/variables';

.subtitle {
  font-weight: 500;
  font-size: calculateRem(14px);
  line-height: calculateRem(19px);
  color: $color_gray;
}

.container {
  width: 100%;
  background: $color_gray_lighter;
  box-sizing: border-box;
  border-radius: 1rem;
  padding: 2.5rem;
  margin-bottom: 1.875rem;
}

.title {
  margin-bottom: 0.75rem !important;
}