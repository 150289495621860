@import 'assets/styles/variables';

.wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 120px;
  position: relative;
}

.map {
  margin-right: 1rem;
  :global .cls-1 {
    fill: $color_light_blue;
    cursor: pointer;
  }
  :global .active {
    fill: $color_primary;
    opacity: 0.5;
  }
}