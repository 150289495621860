@import "assets/styles/variables";
@import "assets/styles/functions";

.stepContainer {
  background: none;
  padding: 0;
}

.wrapper {
  width: 100%;
  background: $color_gray_lighter;
  border-radius: 1rem;
  padding: 1.1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.newGroupName {
  margin-left: 1rem;
  color: $color_primary;
  font-weight: 600;
  font-size: calculateRem(16px);
  line-height: calculateRem(20px);
  cursor: pointer;
}

.groupName {
  margin-left: 1rem;
  color: $color_text;
  font-weight: 500;
  font-size: calculateRem(16px);
  line-height: calculateRem(25px);
}

.groupInfo {
  color: $color_gray_dark;
  line-height: calculateRem(25px);
  span:nth-child(even) {
    font-size: calculateRem(14px);
    font-weight: 600;
  }
  span:nth-child(odd) {
    font-weight: 500;
    font-size: calculateRem(10px);
  }
}

.icon {
  margin-top: .2rem;
  cursor: pointer;
}

.optionsButton {
  button {
    padding: 0 !important;
  }
}
