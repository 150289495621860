@import 'assets/styles/variables';

.cellFirstRow,
.cellSecondRow {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $color_text;
}

.cellFirstRow{
  color: $color_text;
}

.cellSecondRow {
  color: $color_gray;
  margin-top: 8px;
}