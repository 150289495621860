@import "assets/styles/variables";

.wrapper {

  max-height: none;
  & :global {
    .MuiTableRow-root {
      height: auto;
    }
    .MuiTableCell-root {
      background: $color_gray_light;
    }
  }
}
