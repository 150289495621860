@import 'assets/styles/functions';
@import 'assets/styles/variables';

.wrapper {
  margin-bottom: calculateRem(120px) !important;
}

.iconRefresh {
  fill: $color_gray;
  cursor: pointer;
  &:hover {
    fill: $color_gray_dark;
  }
}