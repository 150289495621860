@import 'functions';

$footer_height: 5rem;

$color_primary: #0092dd;
$color_secondary: #b7d317;
$color_accent: #6a237c;
$color_accent_light: #a67bb0;

$color_text: #36314c;
$color_gray_dark: #888888;
$color_gray: #bbbbbb;
$color_gray_middle: #dedede;
$color_gray_light: #ebebeb;
$color_gray_lighter: #f9f9f9;

$color_light_blue: #85D5FF;

$color_error: #f44336;
$link_color_text: #36314c;

//shades of blue
$royalBlue: #005885;
$darkBlue: #0079B8;
$blue: #009BEB;
$cornflowerBlue: #1FB3FF;
$paleCornflowerBlue: #52C4FF;
$lightBlue: #85D5FF;

:export {
  primary: $color_primary;
  gray: $color_gray;
  royalBlue: $royalBlue;
  darkBlue: $darkBlue;
  blue: $blue;
  cornflowerBlue: $cornflowerBlue;
  paleCornflowerBlue: $paleCornflowerBlue;
  lightBlue: $lightBlue;
}
