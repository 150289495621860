@import 'assets/styles/variables';

.tabStepper {
  display: flex;
  justify-content: space-between;
  padding: 0 1.5rem;
  min-height: 4rem;
  border-radius: 1rem !important;
  border: 1px solid $color_gray_middle;

}

.button {
  font-size: 1rem !important;
  color: $color_text !important;
  border-radius: calculateRem(11px) !important;
  margin-top: .55rem !important;
  height: calculateRem(44px) !important;
  &.hidden {
    visibility: hidden;
  }
}