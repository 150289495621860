@import 'assets/styles/variables';

.wrapper {
  display: inline-flex;
  border: 1px solid $color_primary;
  box-sizing: border-box;
  border-radius: 7px;
  padding: .5rem;
  margin: 0 .5rem .5rem 0;
  outline: none;
  cursor: pointer;
}

.label {
  margin-left: .5rem;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: $color_primary;
  word-break: break-all;
}

.disabledWrapper {
  border: 1px solid $color_gray !important;
}

.iconWrapper {
  line-height: .9;
}