@import 'assets/styles/variables';

.wrapper {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color:$color_accent;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}

.label {
  margin-right: .5rem;
}

.value {
  color: $color_gray;
}