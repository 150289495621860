@import 'assets/styles/variables';

.breadcrumbs {
  font-weight: 600 !important;
  font-size: .75rem!important;
  line-height: .75rem !important;
  text-transform: uppercase !important;
  color: $color_gray !important;

  :hover {
    text-decoration: unset !important;
  }
}

.breadcrumbsLast {
  font-weight: 600 !important;
  font-size: .75rem!important;
  line-height: .75rem !important;
  text-transform: uppercase !important;
  color: $color_text !important;
  cursor: pointer;
}