@keyframes blackout {
  from {
    background: rgba(18, 18, 18, 0);
  }
  to {
    background: rgba(18, 18, 18, 0.5);
  }
}

@keyframes slideRight {
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
}

.container {
  padding-left: 0 !important;
  padding-right: 0 !important;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  position: relative;
  &::after {
    animation: blackout 1s ease-in;
    animation-fill-mode: forwards;
    background: rgba(18, 18, 18, 0.5);
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.leftCol {
  position: relative;
  left: -100%;
  animation: slideRight 1s ease-in;
  animation-fill-mode: forwards;
  padding: 3rem 4rem;
  background: #fff;
  z-index: 1;
}

@media screen and (min-width: 600px) {
  .leftCol {
    min-width: 460px;
  }
}

.logo {
  margin-bottom: 5rem;
}
