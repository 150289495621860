@import "assets/styles/variables";

.root {
}

.rootAddCampaign {
}

.indicator,
.indicatorAddCampaign {
  display: flex;
  justify-content: center;
  border-radius: 15px;
  height: 4px !important;
}

.indicator {
  background: $color_primary !important;
}

.indicatorAddCampaign {
  background: $color_accent !important;
}
