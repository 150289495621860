@import 'assets/styles/variables';
@import 'assets/styles/functions';

.editableTitle {
  color: #fff;
  margin-left: 0 !important;

  h2 {
    font-weight: 600;
    font-size: calculateRem(16px);
    line-height: calculateRem(20px);
    margin-bottom: 0 !important;

    input {
      font-weight: 600;
      font-size: calculateRem(16px);
      line-height: calculateRem(20px);
      color: #fff;
    }

    svg {
      vertical-align: middle;
    }
  }
}

.groupInfo {
  color: #fff;
  line-height: calculateRem(25px);
  span:nth-child(even) {
    font-size: calculateRem(14px);
    font-weight: 600;
  }
  span:nth-child(odd) {
    font-weight: 500;
    font-size: calculateRem(10px);
    text-transform: none;
  }
}

.addIcon {
  position: absolute;
  bottom: 1.5rem;
  right: 1.75rem;
  cursor: pointer;
}

.cpmInfo {
  font-weight: 600;
  font-size: calculateRem(16px);
  line-height: calculateRem(20px);
  text-transform: uppercase;
  color: $color_accent;
}

.cpmInput {
  height: 2rem;
  & > div {
    height: 100%;
  }
}

.cpmInfoText {
  font-weight: 500;
  font-size: calculateRem(12px);
  line-height: calculateRem(15px);
  color: $color_gray_dark;
  margin-top: .3rem;
}

.buttonsWrapper {
  background: $color_gray_light !important;
  border-bottom-right-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.cancelButton {
  width: calculateRem(116px) !important;
  height: calculateRem(42px) !important;
  background: #fff !important;
  color: $color_primary !important;
  border-radius: calculateRem(11px) !important;
  padding: .5rem 1rem !important;
  margin: .63rem .75rem !important;
}

.saveButton {
  width: calculateRem(116px) !important;
  height: calculateRem(42px) !important;
  background: $color_primary !important;
  color: #fff !important;
  border-radius: calculateRem(11px) !important;
  padding: .5rem 1rem !important;
  margin: .63rem .75rem !important;
}

.disabledButton {
  color: #fff !important;
  background-color: $color_gray !important;
}
