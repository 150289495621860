.title {
  margin-bottom: 2rem !important;
}

.form {
  margin-bottom: 2rem;
}

.link {
  text-align: right;
}
