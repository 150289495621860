@import 'assets/styles/variables';
@import 'assets/styles/functions';

.link {
  position: relative;
  font-weight: 500;
  font-size: calculateRem(18px);
  line-height: calculateRem(22px);
  color: $link_color_text;
  text-decoration: none;
}

.link:hover,
.active {
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: calculateRem(-12px);
    margin: 0 calculateRem(-12px);
    height: calculateRem(4px);
    background: $color_secondary;
    box-shadow: 0px 10px 30px rgba(209, 244, 16, 0.2);
    border-radius: 15px;
  }
}
