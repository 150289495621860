@import "assets/styles/functions";

.paper {
  margin: calculateRem(40px) !important;
  position: relative !important;
  overflow-y: visible !important;
  padding: calculateRem(40px);
  background: #FFFFFF !important;
  border-radius: calculateRem(16px) !important;
}

.modalCloseButton {
  position: absolute;
  right: calculateRem(-36px);
  top: calculateRem(-36px);
  cursor: pointer;
}
