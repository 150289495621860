@import 'assets/styles/functions';

.wrapper {
  padding: calculateRem(25px) 0;
  align-items: center;
}

.logo {
  svg {
    width: 160px;
    height: 69px;
  }
}