.listbox {
  padding: 20px !important;

  & > * {
    display: inline-flex !important;
    padding: 0 !important;
  }
}
.paper {
  background: #FFFFFF !important;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.12) !important;
  border-radius: 16px !important;
  & > ul {
    max-height: 300px !important;
  }
}

.option {
  &:hover,
  &[aria-selected="true"] {
    background-color: transparent !important;
  }
}

.chipList {
  margin-bottom: 2rem;
}
