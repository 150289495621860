@import "assets/styles/variables";
@import "assets/styles/functions";

.checkboxWrapper {
  display: inline-block;
  position: relative;
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: calculateRem(12px);
  border: 1px solid $color_gray_middle;
  color: #888888;
  font-weight: 500;
  font-size: calculateRem(14px);
  line-height: calculateRem(21px);
  cursor: pointer;
  padding: calculateRem(10px) calculateRem(20px);
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.checkboxChecked {
  color: #121212;
  border: 1px solid $color_primary;
}

.counter {
  display: flex;
  background-color: #fff;
  position: absolute;
  top: calculateRem(-12px);
  right: calculateRem(-12px);
  width: calculateRem(24px);
  height: calculateRem(24px);
  border: 1px solid $color_gray_middle;
  border-radius: calculateRem(15px);
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: calculateRem(14px);
  line-height: calculateRem(21px);
}

.counterChecked {
  border: 1px solid $color_primary;
}

.hiddenCheckbox {
  display: none !important;
}

.disabled {
  cursor: default;
}

.logoBase64 {
  width: 100%;
  height: calculateRem(68px);
  text-align: center;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.logoBase64Label {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  margin-top: .75rem;
  font-weight: 500;
  font-size: calculateRem(14px);
  line-height: calculateRem(21px);
  height: calculateRem(63px);
  color: $color_gray;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  text-align: center;
}

.hideCounter {
  display: none;
}
