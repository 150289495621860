@import 'assets/styles/variables';

.pages {
  display: inline-flex;

  .pagesItem {
    cursor: pointer;
    outline: none;
    color: $color_gray;
    margin: 0 .3rem;
    &.active {
      color: $color_primary;
    }
  }
}