.beacon {
  margin-bottom: 1rem !important;
  fieldset {
    border-width: 0!important;
    border-bottom-width: 1px!important;
    border-radius: 0 !important;
  }
}

.checkbox {
  display: inline-block;
  margin-bottom: 16px;
  margin-left: -10px;
}