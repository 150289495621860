@import 'assets/styles/variables';

.checkboxWrapper {
  background: #EBEBEB;
  border-radius: 7px;
  height: 26px;
  cursor: pointer;
  margin: 0 .5rem .5rem 0;

  & :global {
    .MuiButtonBase-root {
      padding: 5px;
    }
  }
}

.label {
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;

  padding: 5px 5px 5px 0;
  color: $color_gray;
}

.checkboxChecked {
  background: $color_primary;
  .label {
    color: #FFFFFF;
  }
}

.simpleCheckbox {
  background: transparent !important;
  cursor: pointer;
  &.disabled {
    opacity: .4;
    cursor: default;
  }
  span {
    color: black !important;
    font-weight: 400;
    font-size: 14px;
  }
  :global {
    .MuiIconButton-label {
      border: 1px solid #bbbbbb;
      margin-right: .5rem;
    }
  }
}
