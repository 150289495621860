@import "assets/styles/variables";

.creativeFormWrapper {
  padding: 40px;
}

.addButton {
  // width: 100%;
  // height: 100%;
  // border: none;
  // color: $color_gray_dark;
  // font-size: 16px;
  // cursor: pointer;
}