@import "assets/styles/variables";
@import "assets/styles/functions";

.selection {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $color_gray;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.tableItem {
  border-bottom: 1px solid $color_gray_middle;
  height: 1.75rem;
  line-height: 1.75rem;
  font-weight: 600;
  font-size: calculateRem(12px);
  color: $color_text;
  cursor: pointer;
  outline: none;
  &:hover {
    background-color: $color_gray; 
  }
  &.disabled {
    pointer-events: none;
  }
}

.dayItem {
  width: 4.5rem;
  text-align: center;
}

.tableItemHead {
  height: calculateRem(39px);
  line-height: calculateRem(39px);
}

.intervalItem {
  border-left: 1px solid $color_gray_middle;
}

.rotate45 {
  transform: rotate(-55deg);
  text-align: center;
}

.intervalButton {
  white-space: nowrap;
  border-radius: calculateRem(7px) !important;
  border-color: $color_gray !important;
  font-weight: bold !important;
  font-size: calculateRem(12px) !important;
  line-height: calculateRem(15px) !important;
  height: calculateRem(28px) !important;
  width: calculateRem(65px) !important;
  margin-left: -.5rem !important;
  color: $color_gray !important;
}

.intervalButtonActive {
  background-color: $color_primary !important;
  border-color: $color_primary !important;
  color: #fff !important;
  z-index: 10 !important;
}

.wrapper {
  user-select: none;
}

.selectedDayItem {
  font-weight: 600;
  font-size: 1rem;
  line-height: calculateRem(41px);
  color: $color_text;
  height: 41px;
}

.selectedDayChip {
  font-weight: bold;
  font-size: calculateRem(12px);
  line-height: calculateRem(15px);
}

.titleContainer {
  margin-bottom: 0.75rem !important;
}

.container {
  margin-bottom: 1.875rem;
}
