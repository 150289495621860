@import 'assets/styles/variables';

.wrapper {
  position: relative;
  width: 100%;
}

.opened {
  .fakeSelectArrow {
    transform: rotate(180deg);
  }
}

.hasError {
  .fakeSelect {
    border-color: #f44336;
    &:hover, &:focus {
      border-color: #f44336;
    }
  }

  .fakeSelectText {
    color: #f44336;
  }

  .fakeSelectHelperText {
    color: #f44336;
    visibility: visible;
  }
}

.isDisabled {
  .fakeSelectText, .fakeSelectCounter {
    color: #bbbbbb !important;
  }
  .fakeSelect:hover {
    cursor: default;
    border: 1px solid rgba(0, 0, 0, 0.23);
  }
}

.fakeSelect {
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  display: block;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  font-family: Montserrat,sans-serif;
  font-weight: 400;
  line-height: 1.375rem;
  height: 3.5rem;
  border-radius: 0.6875rem;
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 1.0625rem 2rem 1.0625rem;
  font-size: 1.125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover, &:focus {
    border-color: rgba(0, 0, 0, 0.87);
  }
  &:focus {
    outline: none;
    border-color: #0092DD;
  }
}

.fakeSelectArrow {
  right: 7px;
  top: 16px;
  position: absolute;
  color: rgba(0, 0, 0, 0.54);
}

.fakeSelectText {
  color: #0092dd;
}

.fakeSelectCounter {
  color: $color_text;
  margin-left: .25rem;
}

.fakeSelectHelperText {
  visibility: hidden;
  position: absolute;
  margin-left: 14px;
  line-height: 1.66;
  font-size: 0.75rem;
  font-weight: 400;
}

.menu {
  position: relative;
}

.menuSearchWrapper {
  position: absolute !important;
  left: 20px;
  right: 20px;
  z-index: 1;
  background: #fff;
  box-shadow: 0 10px 15px #fff;

  .noResults {
    margin-top: 10px;
    margin-left: 20px;
  }
}

.menuList  {
  padding-top: 65px !important;
  &:focus {
    outline: none;
  }
}

.menuItem {
  padding-left: 40px !important;
}

.menuItemParent {
  padding-left: 20px !important;
  .menuItemText {
    font-weight: 500 !important;
  }
}

.menuItemParentSingle {
  pointer-events: none;
  cursor: default;
}


.menuItemChecked {
  color: #0092DD !important;
}

.menuItemText {
  overflow: hidden;
  text-overflow: ellipsis;
}

.chipList {
  margin-top: 1rem;
}