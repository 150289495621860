.title {
  margin-bottom: 2rem !important;
}

.form {
  margin-bottom: 2rem;
}

.links {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tenantModal {
  width: 800px;
}