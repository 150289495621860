@import "assets/styles/variables";
@import 'assets/styles/functions';

%limitText {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.creative {
  width: 100%;
  height: 100%;
  border: 2px solid $color_gray_dark;
  object-fit: fill;
  &.checked {
    border-color: $color-primary;
  }
}

.label {
  font-weight: 500;
  font-size: calculateRem(10px);
  line-height: calculateRem(12px);
  color: $color_text;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.collapsablePreviewWrapper {
  @include keyframes(expanded-preview){
    0% {
      transform: translateX(0);
    }
    40% {
      transform: translateX(64%);
    }
    50%, 100% {
      transform: translateX(100%);
    }
  }

  @include keyframes(collapsed-preview){
    0%, 30% {
      opacity: 0;
    }
    40%, 100% {
      opacity: 1;
    }
  }

  height: 100%;
  background: url('/images/collapsed-preview-bg.png') no-repeat;
  background-size: contain;
  aspect-ratio: 1/1;
  position: relative;
}
.collapsablePreview {
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  height: 20%;
  bottom: 16.5%;
  font-size: 11px;
  color: white;  
  .expanded {
    @include animate(4s, linear, 1s, infinite, expanded-preview);
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    will-change: width;
  }
  .collapsed {
    @include animate(4s, ease-in, 1s, infinite, collapsed-preview);
    display: flex;
    align-items: center;
    position: absolute;
    opacity: 0;
    width: 36%;
    height: 100%;
    top: 0;
    right: 0;
    will-change: opacity;
  } 
}


.customNativePreview {
  display: flex;
  width: max-content;
  row-gap: 10px;
  border: 2px solid black;

  .mainContainer {
    width: 50%;
    max-width: 150px;
    img {
      width: 100%;
      height: 100%;
    }

    .mainPlaceholder {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100%;
      border: 1px solid black;
    }
  }

  .logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    max-width: 170px;
  }

  .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    .logoPlaceholder {
      width: 50%;
      text-align: center;
    }
    img {
      max-width: 50%;
      max-height: 70px;
      object-fit: contain;
    }
  }

  .title {
    @extend %limitText;
    font-size: 14px;
    margin: 10px 0;
    text-align: center;
  }

  .description {
    @extend %limitText;
    text-align: center;
    font-size: 12px;
    margin: 0 5px;
  }

  .button {
    text-align: center;
  }

  .attribution {
    @extend %limitText;
    font-size: 10px;
    margin: 5px;
    text-align: center;
  }
}